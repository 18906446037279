<template>
  <div>
    <emulators-banner/>
    <div id="app">
      <div v-if="$store.account && $route.path != '/notes/login'">
        <b-navbar>
          <b-button class="burger-btn" @click="toggleSidebar" target="sidebar">
            <span class="navbar-toggler-icon"></span>
          </b-button>
        </b-navbar>
        <b-sidebar id="sidebar" :visible="sidebarOpen" @hidden="sidebarOpen = false">
          <b-nav class="sidebar" vertical>
            <b-nav-item to="/notes/notes"><font-awesome-icon icon="sticky-note" /> Notes</b-nav-item>
            <b-nav-item to="/notes/chat"><font-awesome-icon icon="comments" /> Chat</b-nav-item>
            <b-nav-item to="/notes/simple-chat"><font-awesome-icon icon="comment" /> Simple Chat</b-nav-item>
            <b-nav-item to="/notes/workflow"><font-awesome-icon icon="tasks" /> Workflow</b-nav-item>
            <b-nav-item class="fixed-bottom"><b-button @click="logout"><font-awesome-icon icon="sign-out" />
                Logout</b-button></b-nav-item>
          </b-nav>
        </b-sidebar>
      </div>
      <router-view v-if="$store.account || $route.path == '/notes/login'"></router-view>
    </div>
    <my-footer/>
  </div>
</template>
<script>
import { app } from "@/services/firebase";
import { getAuth } from "firebase/auth";
let auth = getAuth(app);
import { getLog } from "@/services/log";
let log = getLog("notes-router");

export default {
  components: {
    MyFooter: () => import("@/components/Footer.vue"),
    EmulatorsBanner: () => import("@/components/emulatorsBanner.vue"),
  },
  data() {
    return {
      sidebarOpen: false,
      root: "/notes",
    };
  },
  mounted() {
    this.$store.setState(this);
    // account
    this.AuthStateChangedListener = auth.onAuthStateChanged(async (account) => {
      log.log("onAuthStateChanged", account);
      //account = "forced";
      if (!account) {
        if (this.$route.path != "/notes/login")
          this.$router.push("/notes/login");
      }
      this.$store.setAccount(account);
      await this.$store.loadNotes();
      this.$forceUpdate();
      if (account && this.$route.path == this.route("")) {
        this.$router.push(this.route("/notes"));
      }
    });
  },
  methods: {
    route(path) {
        return this.root + path;
    },    
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    logout() {
      // Perform logout logic here
      auth.signOut()
        .then(() => {
          log.log("User signed out");
        })
        .catch(error => {
          log.error("Error signing out:", error);
        });
    }
  }
};
</script>
<style scoped>
.burger-btn {
  /* Add burger button styles here */
  background-color: white;
}

.sidebar {
  font-size: 20pt;
}

.sidebar>b-nav-item>a:hover {
  background-color: #b5b6b6;
}
</style>


